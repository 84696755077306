.box {
  // display: flex;
  // justify-content: center;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  margin-bottom: 40px;
  @media (min-width: 768px) {
    font-size: 60px;
  }
  .line {
    width: 100%;
    height: 2px;
    display: inline-block;
    background-color: black;
    position: absolute;
    left: 0;
  }
  span {
    display: inline-block;
  }
}

.flip-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.flip-card {
  width: 275px;
  height: 490px;
  // margin: auto;
  margin-bottom: 16px;
  &:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
}

.flip-card-back {
  background-color: #fdfffc;
  color: black;
  transform: rotateY(180deg);
  .back-header {
    height: 45%;
    text-align: center;
    img {
      width: 200px;
      height: 150px;
      margin-top: 20px;
    }
  }
  .back-footer {
    // height: 55%;
    // margin: 0;
    padding: 0 15px;
    h2 {
      margin: 0;
    }
    a {
      text-decoration: none;
      display: block;
      text-align: center;
      padding: 7px 5px;
      border: 1px solid #fff;
      color: white;
      margin: 30px auto 0;
      transition: 0.5s;
      background-color: #000;
      overflow: hidden;
      &:hover {
        opacity: 0.8;
        // background: white;
        // border: 1px solid white;
        // color: #000;
      }
    }
  }
}

.flip-card-inner {
  position: relative;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  .flip-card-front img {
    width: 275px;
    height: 490px;
  }
}

.flip-card-back,
.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
