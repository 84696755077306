@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  margin-bottom: 40px; }
  @media (min-width: 768px) {
    .box {
      font-size: 60px; } }
  .box .line {
    width: 100%;
    height: 2px;
    display: inline-block;
    background-color: black;
    position: absolute;
    left: 0; }
  .box span {
    display: inline-block; }

.flip-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around; }

.flip-card {
  width: 275px;
  height: 490px;
  margin-bottom: 16px; }
  .flip-card:hover .flip-card-inner {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

.flip-card-back {
  background-color: #fdfffc;
  color: black;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }
  .flip-card-back .back-header {
    height: 45%;
    text-align: center; }
    .flip-card-back .back-header img {
      width: 200px;
      height: 150px;
      margin-top: 20px; }
  .flip-card-back .back-footer {
    padding: 0 15px; }
    .flip-card-back .back-footer h2 {
      margin: 0; }
    .flip-card-back .back-footer a {
      text-decoration: none;
      display: block;
      text-align: center;
      padding: 7px 5px;
      border: 1px solid #fff;
      color: white;
      margin: 30px auto 0;
      -webkit-transition: 0.5s;
      transition: 0.5s;
      background-color: #000;
      overflow: hidden; }
      .flip-card-back .back-footer a:hover {
        opacity: 0.8; }

.flip-card-inner {
  position: relative;
  height: 100%;
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }
  .flip-card-inner .flip-card-front img {
    width: 275px;
    height: 490px; }

.flip-card-back,
.flip-card-front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.form {
  max-width: 800px;
  margin: 0 auto; }

.field {
  margin-top: 16px; }

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif; }

.container {
  max-width: 1440px;
  margin: 0 auto; }
  .container .wrapper {
    padding: 0 48px; }
    .container .wrapper .home {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%; }
      .container .wrapper .home p {
        font-size: 0.75rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500; }
      .container .wrapper .home h5 {
        margin-top: 260px;
        font-size: 24px;
        font-weight: 600;
        padding-right: 360px; }

header {
  height: 100px; }
  header .inner-header {
    position: relative;
    z-index: 10;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    header .inner-header .logo a {
      font-weight: 700;
      font-size: 1rem;
      text-decoration: none;
      color: #191919; }
    header .inner-header .menu button {
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      font-size: 0.8rem;
      mix-blend-mode: difference; }
  header .hamburger-menu {
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%; }
    header .hamburger-menu .menu-secondary-background-color {
      background: #191919;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1; }
    header .hamburger-menu .menu-layer {
      position: relative;
      background-image: linear-gradient(-20deg, #6e45e2 0%, #88d3ce 100%);
      height: 100%;
      overflow: hidden; }
      header .hamburger-menu .menu-layer .menu-city-background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
        -webkit-animation-name: backgroundEffect;
                animation-name: backgroundEffect;
        -webkit-animation-duration: 30s;
                animation-duration: 30s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite; }
      header .hamburger-menu .menu-layer .wrapper {
        position: relative; }
        header .hamburger-menu .menu-layer .wrapper .menu-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 200px; }
          header .hamburger-menu .menu-layer .wrapper .menu-links nav {
            display: block; }
            header .hamburger-menu .menu-layer .wrapper .menu-links nav ul {
              margin: 0;
              padding: 0; }
              header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li {
                list-style: none;
                font-size: 3rem;
                font-weight: 700;
                cursor: pointer;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 700px; }
                @media (min-width: 768px) {
                  header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li {
                    font-size: 6rem;
                    height: 135px; } }
                header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li a {
                  position: absolute;
                  color: #fff;
                  text-decoration: none; }
                  header .hamburger-menu .menu-layer .wrapper .menu-links nav ul li a:hover {
                    color: #191919; }
          header .hamburger-menu .menu-layer .wrapper .menu-links .info {
            color: #fff;
            width: 300px; }
            header .hamburger-menu .menu-layer .wrapper .menu-links .info h3 {
              font-size: 1.2rem;
              margin: 8px auto; }
            header .hamburger-menu .menu-layer .wrapper .menu-links .info p {
              margin: 0 auto;
              font-size: 0.8rem; }
        header .hamburger-menu .menu-layer .wrapper .locations {
          position: absolute;
          bottom: -80px;
          color: #fff;
          margin-top: 16px;
          font-size: 0.8rem;
          font-weight: 600; }
          header .hamburger-menu .menu-layer .wrapper .locations span {
            cursor: pointer;
            font-weight: 400;
            margin: 0 32px;
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out; }
            header .hamburger-menu .menu-layer .wrapper .locations span:first-child {
              margin-left: 64px; }
            header .hamburger-menu .menu-layer .wrapper .locations span:hover {
              color: #fff;
              background: #191919;
              padding: 8px 24px;
              border-radius: 4px; }

@-webkit-keyframes backgroundEffect {
  0% {
    background-position: 0% 0%; }
  25% {
    background-position: 40% 10%; }
  50% {
    background-position: 0 10%; }
  75% {
    background-position: 10% 40%; }
  100% {
    background-position: 0% 0%; } }

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%; }
  25% {
    background-position: 40% 10%; }
  50% {
    background-position: 0 10%; }
  75% {
    background-position: 10% 40%; }
  100% {
    background-position: 0% 0%; } }

.hero {
  visibility: hidden;
  height: calc(100vh - 100px);
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;
  position: relative; }
  .hero .hero-inner {
    margin: 0 24px;
    height: 100vh; }
    .hero .hero-inner .hero-content {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      padding-bottom: 100px;
      justify-content: center; }
      @media (min-width: 768px) {
        .hero .hero-inner .hero-content {
          position: relative;
          top: 25%;
          justify-content: space-between;
          flex-direction: row; } }
      .hero .hero-inner .hero-content .hero-content-inner {
        color: #323232;
        margin: 0 auto; }
        @media (min-width: 768px) {
          .hero .hero-inner .hero-content .hero-content-inner {
            width: 400px; } }
        .hero .hero-inner .hero-content .hero-content-inner h1 {
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 24px; }
          @media (min-width: 768px) {
            .hero .hero-inner .hero-content .hero-content-inner h1 {
              font-size: 32px; } }
          .hero .hero-inner .hero-content .hero-content-inner h1 .hero-content-line {
            margin: 0;
            overflow: hidden; }
            @media (min-width: 768px) {
              .hero .hero-inner .hero-content .hero-content-inner h1 .hero-content-line {
                height: 44px; } }
        .hero .hero-inner .hero-content .hero-content-inner p {
          font-size: 14px;
          line-height: 24px;
          font-weight: 300;
          padding-right: 48px;
          margin-bottom: 56px; }
        .hero .hero-inner .hero-content .hero-content-inner .btn-row {
          position: relative; }
          .hero .hero-inner .hero-content .hero-content-inner .btn-row:before {
            position: absolute;
            content: "";
            width: 56px;
            height: 1px;
            background: #dfdfdf;
            top: 50%;
            left: -68px; }
          .hero .hero-inner .hero-content .hero-content-inner .btn-row button {
            background: none;
            border: none;
            font-size: 12px;
            padding: 0;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.7px;
            display: flex;
            align-items: center;
            position: relative; }
            .hero .hero-inner .hero-content .hero-content-inner .btn-row button:focus {
              outline: none; }
            .hero .hero-inner .hero-content .hero-content-inner .btn-row button .arrow-icon {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #007fff;
              height: 48px;
              width: 48px;
              border-radius: 100px;
              margin-left: 16px;
              left: 64px;
              -webkit-transform-origin: center center;
                      transform-origin: center center; }
    .hero .hero-inner .hero-images {
      width: 50%;
      height: calc(100vh - 100px);
      position: relative; }
      .hero .hero-inner .hero-images .hero-images-inner .hero-image {
        position: absolute;
        overflow: hidden; }
        .hero .hero-inner .hero-images .hero-images-inner .hero-image.first {
          top: 0;
          right: 0px;
          width: 45%;
          height: 50%; }
        .hero .hero-inner .hero-images .hero-images-inner .hero-image.second {
          bottom: 24px;
          left: 0px;
          width: 52%;
          height: 65%; }
        .hero .hero-inner .hero-images .hero-images-inner .hero-image img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%; }

.wrap {
  margin: 50px auto;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-perspective-origin: 50% 50%;
          perspective-origin: 50% 50%; }
  @media (min-width: 768px) {
    .wrap {
      margin: 0 auto; } }

.cube {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  width: 100px;
  height: 100px;
  margin: auto;
  position: relative;
  -webkit-animation: loop 10s linear infinite;
          animation: loop 10s linear infinite; }
  @media (min-width: 768px) {
    .cube {
      width: 200px;
      height: 200px; } }
  .cube img {
    position: absolute;
    opacity: 0.8;
    height: 100px;
    width: 100px; }
    @media (min-width: 768px) {
      .cube img {
        width: 200px;
        height: 200px; } }

@-webkit-keyframes loop {
  from {
    -webkit-transform: rotateX(0deg) rotate(0deg);
            transform: rotateX(0deg) rotate(0deg); }
  to {
    -webkit-transform: rotateX(360deg) rotateY(360deg);
            transform: rotateX(360deg) rotateY(360deg); } }

@keyframes loop {
  from {
    -webkit-transform: rotateX(0deg) rotate(0deg);
            transform: rotateX(0deg) rotate(0deg); }
  to {
    -webkit-transform: rotateX(360deg) rotateY(360deg);
            transform: rotateX(360deg) rotateY(360deg); } }

