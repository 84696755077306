@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");
@import "./projects.scss";
@import "./contact.scss";

$black: #191919;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  .wrapper {
    padding: 0 48px;
    .home {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      p {
        font-size: 0.75rem;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-weight: 500;
      }
      h5 {
        margin-top: 260px;
        font-size: 24px;
        font-weight: 600;
        padding-right: 360px;
      }
    }
  }
}

header {
  height: 100px;
  .inner-header {
    position: relative;
    z-index: 10;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo a {
      font-weight: 700;
      font-size: 1rem;
      text-decoration: none;
      color: $black;
    }
    .menu {
      button {
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        font-size: 0.8rem;
        mix-blend-mode: difference;
      }
    }
  }
  .hamburger-menu {
    display: none;
    z-index: 9;
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    .menu-secondary-background-color {
      background: $black;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .menu-layer {
      position: relative;
      // background: url(./assets/gradient.png) no-repeat center center fixed;
      // -webkit-background-size: cover;
      // -moz-background-size: cover;
      // -o-background-size: cover;
      // background-size: cover;
      background-image: linear-gradient(-20deg, #6e45e2 0%, #88d3ce 100%);
      height: 100%;
      overflow: hidden;
      .menu-city-background {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        background-size: cover;
        background-repeat: no-repeat;
        animation-name: backgroundEffect;
        animation-duration: 30s;
        animation-iteration-count: infinite;
      }
      .wrapper {
        position: relative;
        .menu-links {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          top: 200px;
          nav {
            display: block;
            ul {
              margin: 0;
              padding: 0;
              li {
                list-style: none;
                font-size: 3rem;
                font-weight: 700;
                cursor: pointer;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 700px;
                @media (min-width: 768px) {
                  font-size: 6rem;
                  height: 135px;
                }
                a {
                  position: absolute;
                  color: #fff;
                  text-decoration: none;
                  &:hover {
                    color: $black;
                  }
                }
              }
            }
          }
          .info {
            color: #fff;
            width: 300px;
            h3 {
              font-size: 1.2rem;
              margin: 8px auto;
            }
            p {
              margin: 0 auto;
              font-size: 0.8rem;
            }
          }
        }
        .locations {
          position: absolute;
          bottom: -80px;
          color: #fff;
          margin-top: 16px;
          font-size: 0.8rem;
          font-weight: 600;
          span {
            cursor: pointer;
            &:first-child {
              margin-left: 64px;
            }
            font-weight: 400;
            margin: 0 32px;
            transition: 0.3s ease-in-out;
            &:hover {
              color: #fff;
              background: $black;
              padding: 8px 24px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }
}

@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}

// .polymorph {
//   fill: #29abe2;
// }
// body {
//   background: rgb(20, 20, 20);
//   overflow: hidden;
// }

// #turbulence {
//   width: 60%;
//   top: 0;
//   bottom: 0;
//   margin: auto;
//   position: absolute;
//   left: 0;
//   right: 0;
// }
// #logo {
//   filter: url(#displacementFilter);
//   transform-origin: center;
// }

.hero {
  visibility: hidden;
  height: calc(100vh - 100px);
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  .hero-inner {
    margin: 0 24px;
    height: 100vh;
    // height: calc(100vh - 100px);
    // position: absolute;
    // top: 50%;
    // -ms-transform: translateY(-50%);
    // transform: translateY(-50%);
    .hero-content {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      padding-bottom: 100px;
      justify-content: center;

      @media (min-width: 768px) {
        position: relative;
        top: 25%;
        justify-content: space-between;
        flex-direction: row;
      }

      .hero-content-inner {
        // width: 400px;
        color: #323232;
        margin: 0 auto;
        @media (min-width: 768px) {
          width: 400px;
        }
        h1 {
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 24px;
          @media (min-width: 768px) {
            font-size: 32px;
          }
          .hero-content-line {
            margin: 0;
            overflow: hidden;
            @media (min-width: 768px) {
              height: 44px;
            }
            .hero-content-line-inner {
            }
          }
        }
        p {
          font-size: 14px;
          line-height: 24px;
          font-weight: 300;
          padding-right: 48px;
          margin-bottom: 56px;
        }
        .btn-row {
          position: relative;
          &:before {
            position: absolute;
            content: "";
            width: 56px;
            height: 1px;
            background: #dfdfdf;
            top: 50%;
            left: -68px;
          }
          button {
            background: none;
            border: none;
            font-size: 12px;
            padding: 0;
            cursor: pointer;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.7px;
            display: flex;
            align-items: center;
            position: relative;
            &:focus {
              outline: none;
            }
            .arrow-icon {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #007fff;
              height: 48px;
              width: 48px;
              border-radius: 100px;
              margin-left: 16px;
              left: 64px;
              transform-origin: center center;
            }
          }
        }
      }
    }
    .hero-images {
      width: 50%;
      height: calc(100vh - 100px);
      position: relative;
      // @media screen and (max-width: $break-small) {
      //   display: none;
      // }
      .hero-images-inner {
        .hero-image {
          position: absolute;
          overflow: hidden;
          &.first {
            top: 0;
            right: 0px;
            width: 45%;
            height: 50%;
          }
          &.second {
            bottom: 24px;
            left: 0px;
            width: 52%;
            height: 65%;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

.wrap {
  margin: 50px auto;
  perspective: 1000px;
  perspective-origin: 50% 50%;
  @media (min-width: 768px) {
    margin: 0 auto;
  }
}
.cube {
  transform-style: preserve-3d;
  width: 100px;
  height: 100px;
  margin: auto;
  position: relative;
  animation: loop 10s linear infinite;
  @media (min-width: 768px) {
    width: 200px;
    height: 200px;
  }
  img {
    position: absolute;
    opacity: 0.8;
    height: 100px;
    width: 100px;
    @media (min-width: 768px) {
      width: 200px;
      height: 200px;
    }
  }
}

@keyframes loop {
  from {
    transform: rotateX(0deg) rotate(0deg);
  }
  to {
    transform: rotateX(360deg) rotateY(360deg);
  }
}
